import React, { useState, useEffect } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Tabs,
  Switch,
  Tooltip
} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import federalTaxNumberValidator from 'util/validators/federalTaxNumberValidator';
import { AddressV2, CustomToolbar, UploadArchiveManual } from 'components';
import ResCol from 'components/forms/ResCol';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  DrawerEdit,
  FormItemWrap,
  ResourceEdit,
  useCrudEditContext,
  useAuthContext
} from 'react-structure-admin';
import {
  cancel,
  findByFederalTaxNumber,
  postCertificate,
  update
} from 'stores/companies/companyActions';
import {
  cityNormalizeFromSelect,
  cityNormalizeToSelect
} from 'util/normalizers';
import { companyUpdate } from 'stores/auth/authActions';
import { formartOnlyNumber } from 'util/formatters';
import CompanyApiKeyLeftToolbar from './CompanyApiKeyLeftToolbar';
import CompanyEditAdministrador from './CompanyEditAdministrador';

const { TabPane } = Tabs;

const specialRegimeOptions = [
  {
    label: 'Sem regime especial',
    value: 'noSpecialRegime'
  },
  {
    label: 'Microempresa Municipal',
    value: 'municipalMicroenterprise'
  },
  {
    label: 'Estimativa',
    value: 'estimate'
  },
  {
    label: 'Sociedade de Profissionais',
    value: 'societyOfProfessionals'
  },
  {
    label: 'Cooperativa',
    value: 'cooperative'
  },
  {
    label: 'Microempresário Individual (MEI)',
    value: 'individualMicroenterprise'
  },
  {
    label: 'Microempresário e Empresa de Pequeno Porte (ME EPP)',
    value: 'microenterpriseAndSmallBusiness'
  }
];

const crtOptions = [
  {
    label: 'Simples Nacional',
    value: 'simplesNacional'
  },
  {
    label: 'Simples Nacional - Excesso Sublimite Receita Bruta',
    value: 'simplesNacionalExcessoSublimite'
  },
  {
    label: 'Regime Normal',
    value: 'regimeNormal'
  }
];

const CompanyEdit = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    data = {},
    params: { uploading = false }
  } = props;
  const { certificate } = data;

  const dispatch = useDispatch();
  const { form } = useCrudEditContext();
  const { tab = 'data' } = useSelector((state) => state.resources.companies);
  const [currentTab, setCurrentTab] = useState(tab);

  useEffect(() => {
    setCurrentTab(tab);
  }, [tab]);

  const handleBeforeSubmit = ({ address, uploadCertificate, ...rest }) => {
    if (rest.certificate !== null && rest.certificate.id === undefined) {
      rest.certificate = null;
    }
    return {
      ...data,
      ...rest,
      address: {
        ...address,
        city: cityNormalizeFromSelect(address.city)
      }
    };
  };

  const handleBeforeBinding = ({ address, ...rest }) => {
    address = address || {};
    return {
      ...rest,
      address: {
        ...address,
        city: cityNormalizeToSelect(address.city)
      },
      certificate: {
        ...rest.certificate,
        expirationDate:
          certificate?.expirationDate != null
            ? moment(certificate?.expirationDate).format('DD/MM/YYYY')
            : null
      }
    };
  };

  const handleSuccess = ({ data = {} }) => {
    form.setFieldsValue({
      ...data.result
    });
    setLoading(false);
  };

  const handleChangeFederalTaxNumber = (e) => {
    const federalTaxNumber = formartOnlyNumber(e.target.value);

    if (federalTaxNumber.length === 14) {
      setLoading(true);
      dispatch(
        findByFederalTaxNumber(federalTaxNumber, handleSuccess, () => {
          setLoading(false);
          message.error('CNPJ não encontrado');
          return true;
        })
      );
    }
  };

  const onAfterUpdate = ({ data: { result } }) => {
    dispatch(
      companyUpdate({
        useCombinedInvoice: result.address.city.useCombinedInvoice
      })
    );
    message.success('Registro salvo com sucesso.');
  };

  const handleSubmit = (data) => {
    const values = handleBeforeSubmit(data);
    dispatch(update(values, onAfterUpdate));
  };

  const handleCancel = () => {
    dispatch(cancel());
  };

  const handleOnUploadRequest = (options) => {
    form.setFields([
      {
        name: ['uploadCertificate', 'file'],
        errors: [],
        value: options.file
      }
    ]);
  };

  const handlePostCertificate = () => {
    const { uploadCertificate = {} } = form.getFieldsValue();
    const { file, password } = uploadCertificate;

    if (!file) {
      form.setFields([
        {
          name: ['uploadCertificate', 'file'],
          errors: message.error('Certificado é obrigatório'),
          value: null
        }
      ]);
    }

    if (!password) {
      form.setFields([
        {
          name: ['uploadCertificate', 'password'],
          errors: message.error('Senha é obrigatória'),
          value: null
        }
      ]);
    }

    if (!file || !password) {
      return;
    }

    const formData = new FormData();
    formData.append('certificateFile', file);
    formData.append('password', password);

    dispatch(postCertificate(data.id, formData));
  };

  return (
    <DrawerEdit
      {...props}
      onSubmit={handleSubmit}
      cancelEdit={handleCancel}
      onAfterUpdate={onAfterUpdate}
      onBeforeBinding={handleBeforeBinding}
      toolbarComponent={(actions) => (
        <CustomToolbar
          {...actions}
          leftComponent={<CompanyApiKeyLeftToolbar id={data.id} />}
        />
      )}
      size="md"
    >
      <Form layout="vertical">
        <Tabs activeKey={currentTab} onChange={(tab) => setCurrentTab(tab)}>
          <TabPane tab="Dados" key="data">
            <Row>
              <ResCol span={9}>
                <FormItemWrap name="federalTaxNumber" label="CNPJ" required>
                  <MaskedInput
                    mask="11.111.111/1111-11"
                    onChange={handleChangeFederalTaxNumber}
                    disabled
                  />
                </FormItemWrap>
              </ResCol>

              <ResCol span={15}>
                <FormItemWrap
                  name="name"
                  label="Nome fantasia"
                  required
                  whitespace
                  validateTrigger="onBlur"
                >
                  <Input maxLength={80} />
                </FormItemWrap>
              </ResCol>
            </Row>

            <Row>
              <ResCol span={12}>
                <FormItemWrap
                  name="legalName"
                  label="Razão Social"
                  required
                  whitespace
                  validateTrigger="onBlur"
                >
                  <Input maxLength={80} />
                </FormItemWrap>
              </ResCol>

              <ResCol span={6}>
                <FormItemWrap name="stateTaxNumber" label="Insc Estadual">
                  <Input maxLength={20} />
                </FormItemWrap>
              </ResCol>

              <ResCol span={6}>
                <FormItemWrap
                  name="cityTaxNumber"
                  label="Insc Municipal"
                  whitespace
                >
                  <Input maxLength={20} />
                </FormItemWrap>
              </ResCol>
            </Row>
            <Row>
              <ResCol span={12}>
                <FormItemWrap label="CRT" name="taxRegime">
                  <Select>
                    {crtOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItemWrap>
              </ResCol>

              <ResCol span={12}>
                <FormItemWrap label="Regime Especial" name="specialTaxRegime">
                  <Select>
                    {specialRegimeOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItemWrap>
              </ResCol>
            </Row>
            <Row>
              <ResCol span={10}>
                <FormItemWrap label="E-mail" name="email" type="email">
                  <Input maxLength={50} />
                </FormItemWrap>
              </ResCol>

              <ResCol span={7}>
                <FormItemWrap type="phone" label="Telefone" name="phone">
                  <MaskedInput
                    mask="(11) 1111-1111"
                    placeholder="(__) _____-____"
                  />
                </FormItemWrap>
              </ResCol>

              <ResCol span={7}>
                <FormItemWrap
                  type="mobilePhone"
                  label="Celular"
                  name="mobilePhone"
                >
                  <MaskedInput
                    mask="(11) 11111-1111"
                    placeholder="(__) ______-____"
                  />
                </FormItemWrap>
              </ResCol>
            </Row>

            <Divider orientation="left">Endereço</Divider>
            <AddressV2 />
          </TabPane>

          <TabPane tab="Certificado" key="certificate">
            <Row className="gx-flex-row gx-align-items-center" noStyle>
              <ResCol span={8}>
                <div className="gx-certificate-button">
                  <FormItemWrap
                    label="Certificado"
                    name={['uploadCertificate', 'file']}
                  >
                    <UploadArchiveManual
                      placeholder="Selecione um certificado"
                      extensions={['pfx', 'p12']}
                      onRequest={handleOnUploadRequest}
                    />
                  </FormItemWrap>
                </div>
              </ResCol>

              <ResCol span={12}>
                <FormItemWrap
                  name={['uploadCertificate', 'password']}
                  label="Senha"
                >
                  <Input.Password maxLength={150} />
                </FormItemWrap>
              </ResCol>

              <ResCol span={4}>
                <div className="gx-upload-btn">
                  <Button loading={uploading} onClick={handlePostCertificate}>
                    Enviar
                  </Button>
                </div>
              </ResCol>
            </Row>
            {certificate ? (
              <>
                <Divider orientation="Informações do Certificado" />
                <Row>
                  <ResCol span={24}>
                    <FormItemWrap
                      name={['certificate', 'subjectName']}
                      label="Nome do certificado"
                    >
                      <Input readOnly />
                    </FormItemWrap>
                  </ResCol>
                </Row>
                <Row>
                  <ResCol span={18}>
                    <FormItemWrap
                      name={['certificate', 'issuerName']}
                      label="Nome do emissor"
                    >
                      <Input readOnly />
                    </FormItemWrap>
                  </ResCol>
                  <ResCol span={6}>
                    <FormItemWrap
                      name={['certificate', 'expirationDate']}
                      label="Data de vencimento"
                    >
                      <Input readOnly />
                    </FormItemWrap>
                  </ResCol>
                </Row>
              </>
            ) : null}
          </TabPane>
          <TabPane tab="Contabilidade" key="accounting">
            <Row>
              <ResCol span={8}>
                <FormItemWrap
                  name={['accounting', 'federalTaxNumber']}
                  label="CNPJ"
                  validateTrigger="onBlur"
                  rules={[{ validator: federalTaxNumberValidator }]}
                >
                  <MaskedInput mask="11.111.111/1111-11" />
                </FormItemWrap>
              </ResCol>
              <ResCol span={16}>
                <FormItemWrap
                  name={['accounting', 'responsibleName']}
                  label="Nome do Responsável"
                  whitespace
                >
                  <Input maxLength={150} />
                </FormItemWrap>
              </ResCol>
            </Row>
            <Row>
              <ResCol span={16}>
                <FormItemWrap
                  label="E-mail"
                  name={['accounting', 'email']}
                  type="email"
                >
                  <Input maxLength={80} />
                </FormItemWrap>
              </ResCol>

              <ResCol span={8}>
                <FormItemWrap
                  type="mobilePhone"
                  label="Celular"
                  name={['accounting', 'phoneNumber']}
                >
                  <MaskedInput
                    mask="(11) 11111-1111"
                    placeholder="(__) _____-____"
                  />
                </FormItemWrap>
              </ResCol>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
    </DrawerEdit>
  );
};
export default ResourceEdit.create(CompanyEdit, {
  resource: 'companies'
});
