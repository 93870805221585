export const PRODUCTINVOICE = 'productInvoice';
export const CONSUMERINVOICE = 'consumerInvoice';
export const SERVICEINVOICE = 'serviceInvoice';

export const AFTERWARRENCY = 'afterWarrency';
export const AFTERPAYMENT = 'afterPayment';
export const DISABLED = 'disabled';
export const IMMEDIATELY = 'immediately';

export const BILLETBANK = 'billetBank';
export const CREDITCARD = 'creditCard';
export const DEBITCARD = 'debitCard';
export const PAYPAL = 'paypal';
export const BANKTRANSFER = 'bankTransfer';
export const CASH = 'cash';
export const BALANCEWALLETS = 'balanceWallets';
export const PIX = 'pix';
export const OTHER = 'other';

export const CREATED = 'created';
export const ENQUEUED = 'enqueued';
export const ACTIVE = 'active';
export const CANCELED = 'canceled';
export const SUSPENDED = 'suspended';
export const EXPIRED = 'expired';
export const CANCELLATION_REQUESTED = 'cancellationRequested';
export const REJECTED = 'rejected';
export const AUTHORIZED = 'authorized';
export const RECEIVED = 'received';
export const DENIED = 'denied';
export const AWAITING_PAYMENT = 'awaitingPayment';

export const CUSTOMER = 'customer';
export const PARTNER = 'partner';
export const ADMIN = 'admin';

export const ACCOUNTING = 'accounting';

export const MONTHLY = 'monthly';
export const YEARLY = 'yearly';

export const COMISSION = 'comission';
export const WITHDRAW = 'withdraw';
export const Refund = 'refund';

export const APPROXIMATETAXES_DISABLED = 'disabled';
export const APPROXIMATETAXES_COMPLETE = 'complete';
export const APPROXIMATETAXES_SIMPLIFIED = 'simplified';

export const COUPON_PUBLISHED = 'published';
export const COUPON_DRAFT = 'draft';
export const COUPON_EXPIRED = 'expired';
export const COUPON_USED = 'used';

export const ANNOUNCEMENT_PUBLISHED = 'published';
export const ANNOUNCEMENT_DRAFT = 'draft';
export const ANNOUNCEMENT_ALERT = 'warning';
export const ANNOUNCEMENT_INFORMATION = 'info';

export const TAXPAYER = 'taxpayer';
export const NONTAXPAYER = 'nonTaxpayer';
export const FOREIGNER = 'foreigner';
export const PHYSICALPERSON = 'physicalPerson';
export const LEGALPERSON = 'legalPerson';

export const COMPANY_MUNICIPALITY = 'companyMunicipality';
export const OUTSIDE_COMPANY_MUNICIPALITY = 'outsideCompanyMunicipality';
export const FOREIGN = 'foreign';

export const TAXATIONINMUNICIPALITY = 'taxationInMunicipality';
export const TAXATIONOUTSIDEMUNICIPALITY = 'taxationOutsideMunicipality';
export const EXEMPTION = 'exemption';
export const IMMUNE = 'immune';
export const SUSPENDEDBYCOURT = 'suspendedByCourt';
export const SUSPENDEDBYADMINISTRATIVEPROCEDURE =
  'suspendedByAdministrativeProcedure';
export const EXPORTATION = 'exportation';

export const AUTHORIZATION = 'authorization';
export const REJECTION = 'rejection';
export const CANCELLATION = 'cancellation';
export const DENEGATION = 'denegation';
export const CONTINGENCY = 'contingency';
export const EVENTRECEIVED = 'received';
export const ERROR = 'error';
export const CORRECTIONLETTER = 'correctionLetter';
export const CREATION = 'creation';
export const CONFIRMOPERATION = 'confirmOperation';
export const AWAREOPERATION = 'awareOperation';
export const UNAWAREOPERATION = 'unawareOperation';
export const OPERATIONNOTPERFORMED = 'operationNotPerformed';
export const DISABLEMENT = 'disablement';
export const ACCESSKEYCHANGED = 'accessKeyChanged';

export const CERTIFICATE = 'certificate';
export const USERNAME = 'userName';
export const TOKEN = 'token';

export const ACTIVATED = 'activated';
export const WAITINGFORACTIVATION = 'waitingForActivation';

export const SUPPRESSISSAMOUNTONSIMPLESNACIONAL =
  'suppressIssAmountOnSimplesNacional';
export const REQUIRESISSBASETAX = 'requiresIssBaseTax';

export const LAUNCHAGENCY = 'launchAgency';
export const AFFILIATE = 'affiliate';

export const INPROCESSING = 'inProcessing';
export const REGISTERED = 'registered';

export const display = {
  [PRODUCTINVOICE]: 'NF-e',
  [CONSUMERINVOICE]: 'NFC-e',
  [SERVICEINVOICE]: 'NFS-e',
  [AFTERWARRENCY]: 'Após o período de garantia',
  [AFTERPAYMENT]: 'Após aprovação do pagamento',
  [IMMEDIATELY]: 'Na cobrança',
  [DISABLED]: 'Manualmente',
  [BILLETBANK]: 'Boleto',
  [CREDITCARD]: 'Cartão de crédito',
  [DEBITCARD]: 'Cartão de débito',
  [PAYPAL]: 'PayPal',
  [CASH]: 'Dinheiro',
  [BANKTRANSFER]: 'Transferência bancária',
  [BALANCEWALLETS]: 'Saldo na Carteira',
  [PIX]: 'Pix',
  [OTHER]: 'Outra',
  [IMMEDIATELY]: 'Imediatamente',
  [CREATED]: 'Aguardando pagamento',
  [ACTIVE]: 'Ativo',
  [CANCELED]: 'Cancelado',
  [SUSPENDED]: 'Suspensa',
  [AUTHORIZED]: 'Autorizado',
  [EXPIRED]: 'Vencido',
  [CUSTOMER]: 'Cliente',
  [PARTNER]: 'Parceiro',
  [ACCOUNTING]: 'Contabilidade',
  [ADMIN]: 'Administrador',
  [MONTHLY]: 'Mensal',
  [YEARLY]: 'Anual',
  [APPROXIMATETAXES_COMPLETE]: 'Completo',
  [APPROXIMATETAXES_SIMPLIFIED]: 'Simplificado',
  [CANCELLATION_REQUESTED]: 'Cancelamento solicitado',
  [ACTIVATED]: 'Ativado',
  [WAITINGFORACTIVATION]: 'Aguardando aprovação',
  [LAUNCHAGENCY]: 'Agência de lançamento',
  [AFFILIATE]: 'Afiliado'
};

export const couponDisplay = {
  [COUPON_PUBLISHED]: 'Publicado',
  [COUPON_DRAFT]: 'Rascunho',
  [COUPON_EXPIRED]: 'Expirado',
  [COUPON_USED]: 'Utilizado'
};

export const announcementDisplay = {
  [ANNOUNCEMENT_PUBLISHED]: 'Publicado',
  [ANNOUNCEMENT_DRAFT]: 'Rascunho',
  [ANNOUNCEMENT_ALERT]: 'Alerta',
  [ANNOUNCEMENT_INFORMATION]: 'Informação'
};

export const invoiceDisplay = {
  [DISABLED]: 'Inutilizada',
  [CANCELED]: 'Cancelada',
  [RECEIVED]: 'Aguardando autorização',
  [ENQUEUED]: 'Em processamento',
  [SUSPENDED]: 'Suspensa',
  [AUTHORIZED]: 'Autorizada',
  [REJECTED]: 'Rejeitada',
  [DENIED]: 'Denegado',
  [CREATED]: 'Pendente'
};

export const InvoiceDisablementDisplay = {
  [INPROCESSING]: 'Em Processamento',
  [REGISTERED]: 'Registrado',
  [REJECTED]: 'Rejeitado',
  [ERROR]: 'Erro'
};

export const customerTypeDisplay = {
  [TAXPAYER]: 'Contribuinte',
  [NONTAXPAYER]: 'Não Contribuinte',
  [FOREIGNER]: 'Estrangeiro',
  [PHYSICALPERSON]: 'Pessoa fisica',
  [LEGALPERSON]: 'Pessoa jurídica'
};

export const locationTypesDisplay = {
  [COMPANY_MUNICIPALITY]: 'No munícipio da empresa',
  [OUTSIDE_COMPANY_MUNICIPALITY]: 'Fora do munícipio da empresa',
  [FOREIGN]: 'Exterior'
};

export const taxationTypeDisplay = {
  [TAXATIONINMUNICIPALITY]: 'Tributado no Município',
  [TAXATIONOUTSIDEMUNICIPALITY]: 'Tributado em outro município',
  [EXEMPTION]: 'Isento',
  [IMMUNE]: 'Imune',
  [SUSPENDEDBYCOURT]: 'Suspenso por Decisão Judicial',
  [SUSPENDEDBYADMINISTRATIVEPROCEDURE]: 'Suspenço por Decisão Administrativa',
  [EXPORTATION]: 'Exportação'
};

export const invoiceEventTypeDisplay = {
  [AUTHORIZATION]: 'Autorização',
  [REJECTION]: 'Rejeição',
  [CANCELLATION]: 'Cancelamento',
  [DENEGATION]: 'Denegação',
  [CONTINGENCY]: 'Contingência',
  [EVENTRECEIVED]: 'Recebimento',
  [ERROR]: 'Falha',
  [CORRECTIONLETTER]: 'Carta de correção',
  [CREATION]: 'Criação',
  [ENQUEUED]: 'Processamento',
  [CONFIRMOPERATION]: 'Confirmaçãço da operação',
  [AWAREOPERATION]: 'Ciência da operação',
  [UNAWAREOPERATION]: 'Desconhecimento da operação',
  [OPERATIONNOTPERFORMED]: 'Operação não realizada',
  [DISABLEMENT]: 'Operação não realizada',
  [ACCESSKEYCHANGED]: 'Operação não realizada'
};

export const paymentMethodDisplay = {
  [CREDITCARD]: 'Cartão de crédito',
  [BILLETBANK]: 'Boleto',
  [PIX]: 'Pix'
};

export const issCalculationModesDisplay = {
  [SUPPRESSISSAMOUNTONSIMPLESNACIONAL]:
    'Suprimir o valor quando a empresa for Simples Nacional',
  [REQUIRESISSBASETAX]: 'Sempre gerar a base de cálculo'
};

export const providerSettingsAuthenticationDisplay = {
  [CERTIFICATE]: 'Certificado digital',
  [USERNAME]: 'Usuário e Senha',
  [TOKEN]: 'Token / Chave de API'
};
