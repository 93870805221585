import React from 'react';
import { Alert, Button, Row, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import { useAuthContext } from 'react-structure-admin';
import {
  showSubscriptionPaymentMethod,
  changeSubscriptionPlan,
  openSubscriptionAvailablePaymentMethod
} from 'stores/subscriptions/subscriptionActions';
import { Footer } from 'components';
import formatCurrency from 'util/formatters/formatCurrency';
import { CREDITCARD, display } from 'constants/types';
import SubscriptionStatus from './SubscriptionStatus';
import ResCol from 'components/forms/ResCol';
import moment from 'moment';

const SubscriptionDetail = ({ subscription = {}, view, width = '100%' }) => {
  const { subscription: authSubscription } = useAuthContext();
  const { status, plan, paymentMethod, amount } = subscription;
  const dispatch = useDispatch();

  const handleChangePlanClick = () => {
    dispatch(changeSubscriptionPlan());
  };

  const handleChangePaymentMethod = () => {
    dispatch(showSubscriptionPaymentMethod());
  };

  const handleChangeAvailablePaymentMethod = () => {
    dispatch(openSubscriptionAvailablePaymentMethod());
  };

  const allowChangePlan = () => {
    if (view) {
      return false;
    }

    if (status === 'suspended' || authSubscription?.lastPaymentIsDue) {
      return false;
    }

    return true;
  };

  const hasPaymentMethod =
    paymentMethod && amount > 0 && paymentMethod?.type === CREDITCARD;

  const allowedPaymentMethods = plan.paymentMethod.filter(
    (c) => c !== paymentMethod.type
  );

  return (
    <>
      {authSubscription?.schedulePlan ? (
        <Alert
          showIcon
          message={
            <div>
              <span>
                Sua alteração para o plano
                <strong>{` ${authSubscription?.schedulePlan?.name} `}</strong>{' '}
                entrará em vigor em{' '}
                <strong>
                  {`${moment(authSubscription.expireAt).format('DD/MM/YYYY')}`}
                </strong>
                .
              </span>
            </div>
          }
        />
      ) : null}

      {subscription.plan && (
        <div className="gx-subscription-list">
          <Row>
            <ResCol span={15} md={22}>
              <div className="card" style={{ width }}>
                <div className="card-header">
                  <h1>
                    {amount === 0 ? (
                      <span>Grátis</span>
                    ) : (
                      <span>{formatCurrency(amount)}</span>
                    )}
                  </h1>
                  <span>{plan.name}</span>
                </div>
                <div className="card-body gx-p-3">
                  <div>
                    <ul>
                      {plan.features.map((f) => (
                        <li key={f.id}>{f.description}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </ResCol>
            <ResCol span={9} md={24}>
              {!view ? (
                <div>
                  <div className="gx-m-2">
                    <div className="gx-fs-lg gx-mb-1">Situação</div>
                    <SubscriptionStatus status={status} />
                  </div>
                  {hasPaymentMethod ? (
                    <div className="gx-m-2">
                      <div className="gx-mb-1">
                        <span className="gx-fs-lg gx-mr-2">
                          Forma de Pagamento
                        </span>
                      </div>
                      <Tag>{display[paymentMethod?.type]}</Tag>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </ResCol>
          </Row>
        </div>
      )}

      <Footer>
        {allowedPaymentMethods.length !== 0 ? (
          <Button onClick={handleChangeAvailablePaymentMethod}>
            Alterar forma de pagamento
          </Button>
        ) : null}
        {hasPaymentMethod ? (
          <Button onClick={handleChangePaymentMethod}>
            Alterar cartão de crédito
          </Button>
        ) : null}
        {allowChangePlan() && (
          <Button
            onClick={handleChangePlanClick}
            disabled={!!authSubscription?.schedulePlan}
          >
            Alterar Plano
          </Button>
        )}
      </Footer>
    </>
  );
};

export default SubscriptionDetail;
