import { dispatcher, api } from 'react-structure-admin';

export const PLATFORM = 'PLATFORM';
export const PLATFORM_GET = 'PLATFORM_GET';
export const PLATFORM_GET_FINISHED = 'PLATFORM_GET_FINISHED';
export const PLATFORM_UPDATE = 'PLATFORM_UPDATE';
export const PLATFORM_UPDATE_FINISHED = 'PLATFORM_UPDATE_FINISHED';

export const PLATFORM_SHOW_CONNECTIONS = 'PLATFORM_SHOW_CONNECTIONS';
export const PLATFORM_CLOSE_CONNECTIONS = 'PLATFORM_CLOSE_CONNECTIONS';

export const PLATFORM_RELOAD_CONNECTIONS = 'PLATFORM_RELOAD_CONNECTIONS';
export const PLATFORM_RELOAD = 'PLATFORM_RELOAD';

export const PLATFORM_SHOW_SYNC_ORDERS = 'PLATFORM_SHOW_SYNC_ORDERS';
export const PLATFORM_CLOSE_SYNC_ORDERS = 'PLATFORM_CLOSE_SYNC_ORDERS';

export const PLATFORM_SYNC_ORDERS = 'PLATFORM_SYNC_ORDERS';
export const PLATFORM_SYNC_ORDERS_FINISHED = 'PLATFORM_SYNC_ORDERS_FINISHED';

export const PLATFORM_SHOW_FILE_IMPORT_ORDERS =
  'PLATFORM_SHOW_FILE_IMPORT_ORDERS';
export const PLATFORM_CLOSE_FILE_IMPORT_ORDERS =
  'PLATFORM_CLOSE_FILE_IMPORT_ORDERS';

export const PLATFORM_FILE_IMPORT_ORDERS = 'PLATFORM_FILE_IMPORT_ORDERS';
export const PLATFORM_FILE_IMPORT_ORDERS_FINISHED =
  'PLATFORM_FILE_IMPORT_ORDERS_FINISHED';

export const PLATFORM_SHOW_CONNECTION_REMOVE =
  'PLATFORM_SHOW_CONNECTION_REMOVE';
export const PLATFORM_CLOSE_CONNECTION_REMOVE =
  'PLATFORM_CLOSE_CONNECTION_REMOVE';
export const PLATFORM_CONNECTION_REMOVE = 'PLATFORM_CONNECTION_REMOVE';
export const PLATFORM_CONNECTION_REMOVE_FINISHED =
  'PLATFORM_CONNECTION_REMOVE_FINISHED';

export const PLATFORM_SHOW_ADVANCED_PARAMETERS =
  'PLATFORM_SHOW_ADVANCED_PARAMETERS';
export const PLATFORM_CLOSE_ADVANCED_PARAMETERS =
  'PLATFORM_CLOSE_ADVANCED_PARAMETERS';
export const PLATFORM_UPDATE_CONNECTION_PARAMETER =
  'PLATFORM_UPDATE_CONNECTION_PARAMETER';
export const PLATFORM_CLEAR_PARAMETERS = 'PLATFORM_CLEAR_PARAMETERS';

export const getByPlatformId = (platformId) => async (dispatch) => {
  const queryString = new URLSearchParams({ platformId }).toString();

  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'platforms',
    actionType: PLATFORM_GET,
    effect: async () =>
      api.get('platforms', `connection-by-platform-id?${queryString}`)
  });
};

export const getByConnectionId = (connectionId) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'platforms',
    actionType: PLATFORM_GET,
    effect: async () => api.get('platforms', `${connectionId}/connection`)
  });
};

export const update = (payload = {}, onSuccess) => async (dispatch) => {
  const { id } = payload;
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'platforms',
    actionType: PLATFORM_UPDATE,
    payload,
    effect: async () => api.put(`platforms/${id}/connection`, { ...payload }),
    onSuccess
  });
};

export const showConnections = (platform) => (dispatch) => {
  dispatch(
    dispatcher.createAction(PLATFORM_SHOW_CONNECTIONS, 'platforms', platform)
  );
};

export const closeConnections = () => (dispatch) => {
  dispatch(dispatcher.createAction(PLATFORM_CLOSE_CONNECTIONS, 'platforms'));
};

export const reloadConnections = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(
      PLATFORM_RELOAD_CONNECTIONS,
      'platforms/connections'
    )
  );
};

export const reload = () => (dispatch) => {
  dispatch(dispatcher.createAction(PLATFORM_RELOAD, 'platforms'));
};

export const showSyncOrders = (platform, connection) => (dispatch) => {
  dispatch(
    dispatcher.createAction(PLATFORM_SHOW_SYNC_ORDERS, 'platforms', {
      platform,
      connection
    })
  );
};

export const closeSyncOrders = () => (dispatch) => {
  dispatch(dispatcher.createAction(PLATFORM_CLOSE_SYNC_ORDERS, 'platforms'));
};

export const syncOrders = ({ initialDate, connectionId }, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'platforms',
    actionType: PLATFORM_SYNC_ORDERS,
    effect: async () =>
      api.post(
        `platforms/syncronize-orders?initialDate=${initialDate}&connectionId=${connectionId}`
      ),
    onSuccess
  });
};

export const showFileImportOrders = (platform, connection) => (dispatch) => {
  dispatch(
    dispatcher.createAction(PLATFORM_SHOW_FILE_IMPORT_ORDERS, 'platforms', {
      platform,
      connection
    })
  );
};

export const closeFileImportOrders = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(PLATFORM_CLOSE_FILE_IMPORT_ORDERS, 'platforms')
  );
};

export const sendFileImportOrders = (connectionId, file, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'platforms',
    actionType: PLATFORM_FILE_IMPORT_ORDERS,
    effect: async () =>
      api.post(
        `platforms/connections/${connectionId}/import-file-orders`,
        file
      ),
    onSuccess
  });
};

export const showConnectionRemove = (connection, connectionList = false) => (
  dispatch
) => {
  dispatch(
    dispatcher.createAction(PLATFORM_SHOW_CONNECTION_REMOVE, 'platforms', {
      connection,
      connectionList
    })
  );
};

export const closeConnectionRemove = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(PLATFORM_CLOSE_CONNECTION_REMOVE, 'platforms')
  );
};

export const connectionRemove = (connectionId, data, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'platforms',
    actionType: PLATFORM_CONNECTION_REMOVE,
    effect: async () =>
      api.post(`platforms/connections/${connectionId}/remove`, data),
    onSuccess
  });
};

export const showAdvancedParameters = (data) => (dispatch) => {
  dispatch(
    dispatcher.createAction(PLATFORM_SHOW_ADVANCED_PARAMETERS, 'platforms', {
      data
    })
  );
};

export const closeAdvancedParameters = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(PLATFORM_CLOSE_ADVANCED_PARAMETERS, 'platforms')
  );
};

export const createOrUpdateConnectionParameter = (parameters) => (dispatch) => {
  dispatch(
    dispatcher.createAction(PLATFORM_UPDATE_CONNECTION_PARAMETER, 'platforms', {
      parameters
    })
  );
};

export const clearParameters = () => (dispatch) => {
  dispatch(dispatcher.createAction(PLATFORM_CLEAR_PARAMETERS, 'platforms'));
};
