import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Row, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import ResCol from 'components/forms/ResCol';
import { AMEX, DINERS, MASTERCARD, VISA } from 'constants/cardBrands';
import { FormItemWrap, formartOnlyNumber } from 'react-structure-admin';
import Iugu from 'util/iugu';
import InputMask from './InputMask';
import { formatCurrency } from 'util/formatters';

const creditCardMask = {
  default: '9999 9999 9999 9999',
  amex: '9999 999999 99999'
};

const creditCardCodeMask = {
  default: '999',
  amex: '9999'
};

const getBrandIcon = (number) => {
  if (!number) {
    number = '';
  }

  const brand = Iugu.utils.getBrandByCreditCardNumber(number);

  switch (brand) {
    case VISA:
      return ['fab', 'cc-visa'];
    case MASTERCARD:
      return ['fab', 'cc-mastercard'];
    case AMEX:
      return ['fab', 'cc-amex'];
    case DINERS:
      return ['fab', 'cc-diners-club'];
    default:
      return null;
  }
};

const isNumberAmex = (numbers) => {
  if (!numbers) {
    return false;
  }

  return Iugu.utils.getBrandByCreditCardNumber(numbers) === AMEX;
};

const CreditCardInfo = ({
  name,
  resume,
  allowInstallments = false,
  numberOfInstallments = 0
}) => {
  const [brandIcon, setBrandIcon] = useState(getBrandIcon(null));
  const [mask, setMask] = useState({
    number: creditCardMask.default,
    code: creditCardCodeMask.default
  });

  const validateNumber = (rule, value) => {
    if (!value) {
      return Promise.resolve();
    }

    const number = formartOnlyNumber(value);

    if (number.length > 15 && !Iugu.utils.validateCreditCardNumber(number)) {
      return Promise.reject(new Error('Número de cartão inválido'));
    }

    return Promise.resolve();
  };

  const handleChangeNumber = (e) => {
    e.preventDefault();

    const { target } = e;
    const numbers = formartOnlyNumber(target.value);

    if (!numbers) {
      return;
    }

    if (isNumberAmex(numbers)) {
      setMask({ number: creditCardMask.amex, code: creditCardCodeMask.amex });
    } else if (mask.number !== creditCardMask.default) {
      setMask({
        number: creditCardMask.default,
        code: creditCardCodeMask.default
      });
    }

    setBrandIcon(getBrandIcon(numbers));
  };

  const validateExpirationDate = (rule, value) => {
    if (!value) {
      return Promise.resolve();
    }

    const date = formartOnlyNumber(value);

    if (date.length < 6) {
      return Promise.reject(new Error('Data de validade inválida'));
    }

    if (date.length === 6 && !Iugu.utils.validateExpirationString(value)) {
      return Promise.reject(new Error('Data de validade inválida'));
    }

    return Promise.resolve();
  };

  const validateName = (rule, value) => {
    if (!value) {
      return Promise.resolve();
    }

    if (/\d/.test(value)) {
      return Promise.reject(new Error('Nome não pode conter números'));
    }

    if (value.split(' ').filter((c) => c !== '').length === 1) {
      return Promise.reject(new Error('Digite o nome completo'));
    }

    return Promise.resolve();
  };

  const validateSecurityCode = (value, number) => {
    if (!value || !number) {
      return true;
    }

    const cardNumber = formartOnlyNumber(number);
    const cvv = formartOnlyNumber(value);
    const brand = Iugu.utils.getBrandByCreditCardNumber(cardNumber);

    if (
      cardNumber.length === 16 &&
      cvv.length === 3 &&
      !Iugu.utils.validateCVV(cvv, brand)
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      <FormItemWrap
        label="Número do Cartão"
        name={name ? [name, 'number'] : 'number'}
        autoFocus
        rules={[
          { required: true, message: 'Informe o número do cartão' },
          () => ({
            validator: validateNumber
          })
        ]}
      >
        <InputMask
          mask={mask.number}
          onChange={handleChangeNumber}
          suffix={
            brandIcon ? (
              <FontAwesomeIcon
                className="icon gx-subscription-payment-brand-icon gx-fs-xl"
                icon={brandIcon}
              />
            ) : (
              <div />
            )
          }
        />
      </FormItemWrap>
      <FormItemWrap
        label="Nome do titular"
        name={name ? [name, 'cardholderName'] : 'cardholderName'}
        whitespace
        required
        rules={[
          () => ({
            validator: validateName
          })
        ]}
      >
        <Input />
      </FormItemWrap>
      <Row gutter={[10, 0]}>
        <ResCol span={12}>
          <FormItemWrap
            label="Data de Validade"
            name={name ? [name, 'expirationDate'] : 'expirationDate'}
            required
            rules={[
              () => ({
                validator: validateExpirationDate
              })
            ]}
          >
            <MaskedInput mask="11/1111" />
          </FormItemWrap>
        </ResCol>
        <ResCol span={12}>
          <FormItemWrap
            label="Código de Segurança"
            name={name ? [name, 'securityCode'] : 'securityCode'}
            required
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!validateSecurityCode(value, getFieldValue('number'))) {
                    return Promise.reject(
                      new Error('Código de segurança inválido')
                    );
                  }

                  return Promise.resolve();
                }
              })
            ]}
          >
            <InputMask mask={mask.code} />
          </FormItemWrap>
        </ResCol>
      </Row>

      {allowInstallments ? (
        <FormItemWrap
          label="Parcelamento"
          name={name ? [name, 'installments'] : 'installments'}
          required
        >
          <Select
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {Array.from({ length: numberOfInstallments }, (v, k) => k + 1).map(
              (c) => (
                <Select.Option key={c.toString()} value={c}>
                  {`${c}x ${formatCurrency(resume?.amount / c)}`}
                </Select.Option>
              )
            )}
          </Select>
        </FormItemWrap>
      ) : null}
    </>
  );
};

export default CreditCardInfo;
