import {
  dispatcher,
  api,
  RESOURCE_ACTION_EDIT,
  RESOURCE_FETCH
} from 'react-structure-admin';

export const REFERRALPROGRAM = 'REFERRALPROGRAM';
export const REFERRALPROGRAM_GET_WITHDRAW = 'REFERRALPROGRAM_GET_WITHDRAW';
export const REFERRALPROGRAM_GET_WITHDRAW_FINISHED =
  'REFERRALPROGRAM_GET_WITHDRAW_FINISHED';
export const REFERRALPROGRAM_FETCH_ENTRIES = 'REFERRALPROGRAM_FETCH_ENTRIES';
export const REFERRALPROGRAM_FETCH_ENTRIES_FINISHED =
  'REFERRALPROGRAM_FETCH_ENTRIES_FINISHED';
export const REFERRALPROGRAM_SAVE_WITHDRAW = 'REFERRALPROGRAM_SAVE_WITHDRAW';
export const REFERRALPROGRAM_SAVE_WITHDRAW_FINISHED =
  'REFERRALPROGRAM_SAVE_WITHDRAW_FINISHED';
export const REFERRALPROGRAM_CLOSE_WITHDRAW = 'REFERRALPROGRAM_WITHDRAW_CLOSE';

export const fetch = (_, params) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'referral-program',
    actionType: RESOURCE_FETCH,
    effect: async () => api.fetch('referral-program/statement-entries', params)
  });
};

export const get = (onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'referral-program',
    actionType: RESOURCE_ACTION_EDIT,
    effect: async () => {
      return api.get('referral-program', '');
    },
    onSuccess
  });
};

export const createWithdraw = () => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'referral-program',
    actionType: REFERRALPROGRAM_GET_WITHDRAW,
    effect: async () => api.get('companies', 'current-company-bank-account')
  });
};

export const saveWithdraw = (params, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'referral-program',
    actionType: REFERRALPROGRAM_SAVE_WITHDRAW,
    effect: async () => api.post('referral-program/withdraw', params),
    onSuccess
  });
};

export const closeWithdraw = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(REFERRALPROGRAM_CLOSE_WITHDRAW, 'referral-program')
  );
};
