import { CheckCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';

const icons = {
  approved: {
    color: 'success',
    title: 'Aprovado'
  },
  cancelled: {
    color: 'default',
    title: 'Cancelado'
  },
  refunded: {
    color: 'error',
    title: 'Reembolsado'
  },
  chargedBack: {
    color: 'error',
    title: 'Estornado'
  },
  expired: {
    color: 'error',
    title: 'Expirado'
  },
  inProtest: {
    color: 'error',
    title: 'Contestado'
  }
};

const SubscriptionPaymentTag = ({ status }) => {
  let icon = icons[status];

  if (!icon) {
    icon = { title: 'Pendente', color: 'default' };
  }

  return (
    <Tag icon={<CheckCircleOutlined />} color={icon.color}>
      {icon.title}
    </Tag>
  );
};

export default SubscriptionPaymentTag;
