import React from 'react';
import { Form, Input, message } from 'antd';
import { DrawerContainer, Edit, FormItemWrap } from 'react-structure-admin';
import { useDispatch } from 'react-redux';
import {
  updatePassword,
  savePassword,
  savePasswordFailed
} from 'stores/users/userActions';

const UpdatePassword = ({ data = {} }) => {
  const { editingPassword } = data;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onSuccess = () => {
    message.success('A senha foi alterada com sucesso.');
  };

  const onFail = () => {
    dispatch(savePasswordFailed());
  };

  const validatePassword = (_, value) => {
    if (value.length < 8) {
      return Promise.reject(new Error('Mínimo de oito caracteres'));
    } else {
      if (!/[A-Za-z]/.test(value)) {
        return Promise.reject(new Error('É necessário conter letras'));
      }

      if (!/\d/.test(value)) {
        return Promise.reject(new Error('É necessário conter números'));
      }
    }

    return Promise.resolve();
  };

  const okClickHandle = () => {
    form.validateFields().then((values) => {
      if (values.newPassword !== values.passwordConfirmation) {
        message.warn('As senhas não coincidem.');
        return;
      }

      dispatch(savePassword(editingPassword.userId, values, onSuccess, onFail));
    });
  };

  const backClickHandle = () => {
    dispatch(updatePassword(null));
  };

  return (
    <DrawerContainer
      title="Alterar senha"
      onBackClick={backClickHandle}
      onOkClick={okClickHandle}
      visible={data.visible}
      loading={data.loading}
      okButtonText="Confirmar"
      size="sm"
    >
      <Form form={form} layout="vertical">
        <FormItemWrap name="currentPassword" label="Senha atual" required>
          <Input maxLength={30} type="password" />
        </FormItemWrap>
        <FormItemWrap
          name="newPassword"
          label="Nova senha"
          required
          validateTrigger="onBlur"
          rules={[{ validator: validatePassword }]}
        >
          <Input maxLength={30} type="password" />
        </FormItemWrap>
        <FormItemWrap
          name="passwordConfirmation"
          label="Confirmação da senha"
          required
        >
          <Input maxLength={30} type="password" />
        </FormItemWrap>
      </Form>
    </DrawerContainer>
  );
};

export default Edit.create(UpdatePassword, { resource: 'users' });
