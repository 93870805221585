import React from 'react';
import { Table } from 'antd';
import { configManager } from 'react-structure-admin';

const ListTable = (props) => {
  const {
    dataSource,
    loading,
    pagination,
    children,
    initEditing,
    rowSelection,
    className,
    handleTableChange,
    columns,
    components,
    moveRow,
    onDoubleClick,
    disableDoubleClick,
    disablePagination,
    removeRecord,
    showHeader,
    expandable
  } = props;

  const handleDoubleClick = (record) => {
    if (disableDoubleClick) {
      return;
    }

    if (onDoubleClick) {
      onDoubleClick(record, initEditing);
      return;
    }

    if (initEditing) {
      initEditing(record);
    }
  };

  return (
    <Table
      scroll={{ x: 400 }}
      size={configManager.getConfig().layout.list.table.size}
      className={className}
      loading={loading}
      rowKey={(record) => record.id ?? record.uuid}
      rowSelection={rowSelection}
      pagination={disablePagination ? false : pagination}
      onChange={handleTableChange}
      dataSource={dataSource}
      showHeader={showHeader}
      columns={columns}
      expandable={expandable}
      components={components}
      onRow={(record, index) => {
        return {
          onDoubleClick: () => handleDoubleClick(record),
          index,
          moveRow
        };
      }}
    >
      {typeof children === 'function'
        ? children({ initEditing, removeRecord })
        : children}
    </Table>
  );
};

export default ListTable;
