import React, { useState, useEffect } from 'react';
import { MaskedInput } from 'antd-mask-input';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Form, Row, Divider, Select, message } from 'antd';
import UploadArchive from 'components/UploadArchive';
import {
  DrawerEdit,
  ResourceEdit,
  refreshAuthenticatedUser,
  FormItemWrap
} from 'react-structure-admin';
import ResCol from 'components/forms/ResCol';
import AddressV2 from 'components/AddressV2';
import { currentUserCancel, currentUserUpdate } from 'stores/users/userActions';
import { federalTaxNumberValidator } from 'util/validators/index';
import {
  cityNormalizeFromSelect,
  cityNormalizeToSelect
} from 'util/normalizers';

const CurrentUserEdit = (props) => {
  const { data, loading } = props;
  const dispatch = useDispatch();
  const [allowChangeFederalTaxnumber] = useState(data.federalTaxNumber == null);
  const [state, setState] = useState({});
  const { editingByAdmin = false } = useSelector(
    (state) => state.resources.users || {}
  );

  const handleBeforeSubmit = (dataSubmit) => {
    return {
      ...dataSubmit,
      address: {
        ...dataSubmit.address,
        city: cityNormalizeFromSelect(dataSubmit.address.city)
      },
      profileImage: state.profileImage
        ? {
            fileName: state.profileImage.fileName,
            type: state.profileImage.type
          }
        : undefined
    };
  };

  useEffect(() => {
    setState({ profileImage: data.profileImage });
  }, [data.profileImage]);

  const handleBeforeBinding = ({ address, ...data }) => {
    address = address || {};
    return {
      ...data,
      address: {
        ...address,
        city: cityNormalizeToSelect(address.city)
      }
    };
  };

  const handleChangeProfileImage = (profileImage) => {
    setState({ ...state, profileImage });
  };

  const onAfterUpdate = (response) => {
    if (!editingByAdmin && response && response.data) {
      const { result } = response.data;
      dispatch(
        refreshAuthenticatedUser({
          name: result.name,
          displayName: result.displayName,
          profileImage: result.profileImage
        })
      );
    }

    handleCancel();
    message.success('Registro salvo com sucesso.');
  };

  const handleSubmit = (data) => {
    const values = handleBeforeSubmit(data);
    dispatch(currentUserUpdate(values, onAfterUpdate));
  };

  const handleCancel = () => {
    dispatch(currentUserCancel());
  };

  return (
    <DrawerEdit
      {...props}
      data={data}
      saving={loading}
      onSubmit={handleSubmit}
      cancelEdit={handleCancel}
      onAfterUpdate={onAfterUpdate}
      onBeforeBinding={handleBeforeBinding}
      size="md"
      title={editingByAdmin ? 'Editar usuário' : 'Meus dados'}
    >
      <Form>
        <Row>
          <ResCol span={16}>
            <Row>
              <ResCol span={10}>
                <FormItemWrap
                  label="CPF"
                  name="federalTaxNumber"
                  whitespace
                  required
                  rules={[{ validator: federalTaxNumberValidator }]}
                >
                  <MaskedInput
                    mask="111.111.111-11"
                    disabled={!allowChangeFederalTaxnumber}
                  />
                </FormItemWrap>
              </ResCol>
              <ResCol span={14}>
                <FormItemWrap label="Nome" name="name" required whitespace>
                  <Input maxLength={100} />
                </FormItemWrap>
              </ResCol>
            </Row>

            <Row>
              <ResCol span={10}>
                <FormItemWrap label="Celular" name="phoneNumber" required>
                  <MaskedInput mask="(11) 11111-1111" />
                </FormItemWrap>
              </ResCol>
              <ResCol span={14}>
                <FormItemWrap
                  label="E-mail"
                  name="email"
                  required
                  whitespace
                  type="email"
                >
                  <Input maxLength={50} />
                </FormItemWrap>
              </ResCol>
            </Row>

            <ResCol span={10}>
              <FormItemWrap label="Sexo" name="gender">
                <Select>
                  <Select.Option disabled value={0}>
                    Selecione o gênero
                  </Select.Option>
                  <Select.Option key="male" value="male">
                    Masculino
                  </Select.Option>
                  <Select.Option key="female" value="female">
                    Feminino
                  </Select.Option>
                </Select>
              </FormItemWrap>
            </ResCol>
          </ResCol>

          <ResCol span={8}>
            <FormItemWrap className="gx-upload-profile-image">
              <UploadArchive
                name="profileImageFile"
                resource="users/profile-image"
                onChange={handleChangeProfileImage}
                onBeforeSubmit={handleBeforeSubmit}
                params={data.id ? { id: data.id } : {}}
                initialValue={
                  data.profileImage && data.profileImage.type !== 'letter'
                    ? data.profileImage.base64
                    : null
                }
              />
            </FormItemWrap>
          </ResCol>
        </Row>

        <Divider orientation="left">Endereço</Divider>

        <AddressV2 />
      </Form>
    </DrawerEdit>
  );
};
export default ResourceEdit.create(CurrentUserEdit, {
  resource: 'users',
  currentAttr: 'currentUser'
});
