import { dispatcher, api } from 'react-structure-admin';
import getEndpointByInvoiceModel from 'util/getEndpointByInvoiceModel';

export const INVOICE = 'INVOICE';
export const INVOICE_OPEN_CANCEL = 'INVOICE_OPEN_CANCEL';
export const INVOICE_CLOSE_CANCEL = 'INVOICE_CLOSE_CANCEL';
export const INVOICE_CANCEL = 'INVOICE_CANCEL';
export const INVOICE_CANCEL_FINISHED = 'INVOICE_CANCEL_FINISHED';
export const INVOICE_REFRESH = 'INVOICE_REFRESH';

export const INVOICE_OPEN_EVENTS = 'INVOICE_OPEN_EVENTS';
export const INVOICE_CLOSE_EVENTS = 'INVOICE_CLOSE_EVENTS';
export const INVOICE_GET_EVENTS = 'INVOICE_GET_EVENTS';
export const INVOICE_GET_EVENTS_FINISHED = 'INVOICE_GET_EVENTS_FINISHED';

export const INVOICE_OPEN_REMOVE = 'INVOICE_OPEN_REMOVE';
export const INVOICE_CLOSE_REMOVE = 'INVOICE_CLOSE_REMOVE';
export const INVOICE_REMOVE = 'INVOICE_REMOVE';
export const INVOICE_REMOVE_FINISHED = 'INVOICE_REMOVE_FINISHED';

export const INVOICE_CREATE_DISABLEMENT = 'INVOICE_CREATE_DISABLEMENT';
export const INVOICE_CREATE_DISABLEMENT_FINISHED =
  'INVOICE_CREATE_DISABLEMENT_FINISHED';

export const INVOICE_REFRESH_DISABLEMENT = 'INVOICE_REFRESH_DISABLEMENT';

export const openInvoiceCancel = (id, model) => (dispatch) => {
  dispatch(dispatcher.createAction(INVOICE_OPEN_CANCEL, 'invoices', id, model));
};

export const closeInvoiceCancel = () => (dispatch) => {
  dispatch(dispatcher.createAction(INVOICE_CLOSE_CANCEL, 'invoices'));
};

export const cancelInvoice = (data) => async (dispatch) => {
  const endpoint = getEndpointByInvoiceModel(data.model);
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'invoices',
    actionType: INVOICE_CANCEL,
    effect: async () => api.post(`${endpoint}/${data.id}/cancel`, { ...data })
  });
};

export const openInvoiceEvents = (id, number) => (dispatch) => {
  dispatch(
    dispatcher.createAction(INVOICE_OPEN_EVENTS, 'invoices', { id, number })
  );
};

export const closeInvoiceEvents = () => (dispatch) => {
  dispatch(dispatcher.createAction(INVOICE_CLOSE_EVENTS, 'invoices'));
};

export const getEvents = (invoice) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'invoices',
    actionType: INVOICE_GET_EVENTS,
    payload: invoice,
    effect: async () => api.fetch(`invoices/${invoice.id}/events`, {})
  });
};

export const refresh = () => (dispatch) => {
  dispatch(dispatcher.createAction(INVOICE_REFRESH, 'invoices'));
};

export const openInvoiceRemove = (invoice, order) => (dispatch) => {
  dispatch(
    dispatcher.createAction(INVOICE_OPEN_REMOVE, 'invoices', { invoice, order })
  );
};

export const closeInvoiceRemove = () => (dispatch) => {
  dispatch(dispatcher.createAction(INVOICE_CLOSE_REMOVE, 'invoices'));
};

export const removeInvoice = (id) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'invoices',
    actionType: INVOICE_REMOVE,
    effect: async () => api.post(`invoices/${id}/remove`)
  });
};

export const createDisablements = (data, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'disablements',
    actionType: INVOICE_CREATE_DISABLEMENT,
    effect: async () => api.post('product-invoices/disablements', data),
    onSuccess
  });
};

export const refreshDisablements = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(INVOICE_REFRESH_DISABLEMENT, 'disablements')
  );
};
